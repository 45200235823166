body, html {
    margin: 0;
    padding: 0;
}

*, 
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --ff-primary: 'Work Sans', sans-serif;
    --ff-secondary: 'Roboto Mono', monospace;
        
    --fw-reg: 400;
    --fw-bold: 600;
 
    --clr-light: #eceff7;
    --clr-bg: #B4CDED;
    --clr-dark: #293c57;
    --clr-accent: #ffffff;
    --clr-font-light: #fff4f4;
    --clr-font-dark: #272c35;

    --fs-toolbar: 2.5rem;
    --fs-h1: 2rem;
    --fs-h2: 1.5rem;
    --fs-body: 1.25rem;
    --fs-icon: 2rem;
}

h1,
h2,
#preview {
    font-family: var(--ff-primary);
}

#editor {
    font-family: var(--ff-secondary);
}

.header h2 {
    font-size: var(--fs-toolbar);
    font-weight: var(--fw-reg);
    color: var(--clr-font-light);
}

#preview h1 {
    font-size: var(--fs-h1);
    font-weight: var(--fw-bold);
    margin-bottom: 0.8em;
    text-align: center;
}

#preview h2 {
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    margin-bottom: 0.8em;
}

h1,
h2,
h3,
p {
    color: var(--clr-font-dark);
}

#preview,
#editor {
    font-size: var(--fs-body);
}

img {
    width: 60%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: 0 auto 1rem auto;
    display: block;
}

body {
    background-color: var(--clr-bg) !important;
}

.wrapper {
    padding: 3rem;
}
 
.header {
    padding: 1em;
    background-color: var(--clr-dark);
}

.editor-container, 
.previewer-container {
    background-color: var(--clr-light);
    margin: 1rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px var(--clr-font-dark);    
}

#preview {
    padding: 1.5rem;
}


pre,
code {
    background-color: var(--clr-accent);
    border-radius: 5px;
}

blockquote {
    border-left: solid 4px var(--clr-dark);
    padding-left: 0.4em;
    margin-bottom: 1.5em;
}

.icon {
    font-size: var(--fs-icon);
    margin-right: 0.3em;
    color: var(--clr-font-light)
}

.header h2 {
    margin: 0;
}

h3 {
    text-align: center;
    font-size: var(--fs-body);
    margin-bottom: 1.5em;
}